import { WHATSAPP_LINK } from '~/constants/social'

export function openTalkToExperts(diamond) {
  // CHECK
  console.log('openTalkToExperts')
}

export const openChat2 = () => {
  if (typeof window.Kustomer?.open === 'function') {
    window.Kustomer.open()
  }
}

export function openwhatsapp() {
  window.open(WHATSAPP_LINK, '_blank')
}

export function checkIfChatExists() {
  return !!document?.getElementById('liveHelp')
}
